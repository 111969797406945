import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import useMedia from './useMedia';
import ImageModel from 'Models/Assets/ImageModel.interface';

export default function useImageUrl(image: ImageModel) {
  const isMobile = useMedia(mediaQueryTypes.bpMax720);
  const isIpad = useMedia(mediaQueryTypes.bpM);

  let imageUrl = '';

  switch (true) {
    case isMobile:
      imageUrl = image?.mobileSrc ?? '';
      break;

    case isIpad:
      imageUrl = image?.iPadSrc ?? '';
      break;

    default:
      imageUrl = image?.src ?? '';
      break;
  }

  return imageUrl;
}
