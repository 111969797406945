import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'Theme/stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import InteractableContentLink, {
  TargetTypes,
} from 'Shared/Components/Links/InteractableContentLink';
import { CSS } from '@stitches/react';
import { extraVisibleFocusStateStyle } from 'Shared/Common/focusState';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import useImageUrl from 'Shared/Hooks/useImageUrl';
import HeroModel from 'Models/Addons/HeroModel.interface';
import { ContentAlignmentTypes } from 'Shared/Types/types';

export type HeroModuleType = {
  hero: HeroModel;
  css?: CSS;
};

function HeroModule({ hero, css }: HeroModuleType) {
  const {
    heading,
    heroText,
    image,
    logoImage,
    backgroundLink,
    link,
    horizontalPosition,
    textMode = 'dark',
  } = hero || {};

  const m = useMedia(mediaQueryTypes.bpMax960);

  const heroImageUrl = useImageUrl(image);

  const renderTextBlock = (withoutHeroImage = false) => {
    return (
      <TextWrapper>
        <Heading
          tag="h1"
          size={m ? 'l' : 'xl'}
          color={
            textMode === 'dark' || withoutHeroImage
              ? 'Regular'
              : 'OnInteractive'
          }
          css={{ marginBottom: '10px' }}
        >
          {heading}
        </Heading>
        <BodyText
          size={m ? 'm' : 'l'}
          color={
            textMode === 'dark' || withoutHeroImage
              ? 'primary'
              : 'oninteractive'
          }
        >
          <div dangerouslySetInnerHTML={{ __html: heroText }} />
        </BodyText>
      </TextWrapper>
    );
  };

  const renderHeroSection = () => {
    return heroImageUrl ? (
      <>
        <ImageContainer src={heroImageUrl} alt={image?.alt ?? ''} />

        <Container
          horizontalContentPosition={
            horizontalPosition as ContentAlignmentTypes
          }
        >
          <HeroWrapper css={css ? css : {}}>
            {logoImage && (
              <LogoImage src={logoImage.src} alt={logoImage.alt ?? ''} />
            )}

            {renderTextBlock()}

            {link && !backgroundLink && (
              <InteractableContentLink
                href={link.href}
                title={link.title}
                target={(link.target as TargetTypes) || '_self'}
                type="hero"
              >
                {link.text}
              </InteractableContentLink>
            )}
          </HeroWrapper>
        </Container>
      </>
    ) : heading || heroText ? (
      <ContentContainer>
        <TextContainer
          css={{ textAlign: horizontalPosition as ContentAlignmentTypes }}
        >
          {renderTextBlock(true)}
        </TextContainer>
      </ContentContainer>
    ) : null;
  };

  return (
    <Root>
      {backgroundLink ? (
        <InteractableContentLink
          href={backgroundLink.href}
          title={backgroundLink.title}
          target={(backgroundLink.target as TargetTypes) || '_self'}
        >
          {renderHeroSection()}
        </InteractableContentLink>
      ) : (
        renderHeroSection()
      )}
    </Root>
  );
}

const Root = styled('div', {
  position: 'relative',
  '@bpMin721': { wh: '100%' },
  maxWidth: '$screenMaxWidth',
  margin: '0 auto',
});

const ImageContainer = styled('img', {
  w: '100%',
});

const Container = styled('div', {
  display: 'flex',
  position: 'absolute',
  tblr: 0,
  alignItems: 'end',
  '@bpMin721': {
    alignItems: 'center',
  },
  maxW: '$screenMaxWidth',
  variants: {
    horizontalContentPosition: {
      left: {
        justifyContent: 'start',
      },
      center: {
        justifyContent: 'center',
      },
      right: {
        '@bpMin721': {
          justifyContent: 'end',
        },
      },
    },
  },
});

const HeroWrapper = styled('div', {
  color: '$onInteractivePrimary',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'end',
  g: 16,
  w: '100%',
  h: '50%',
  px: 6,
  pt: 0,
  pb: 8,
  '& a:focus-visible': {
    ...extraVisibleFocusStateStyle,
  },
  '@bpMin721': {
    textAlign: 'left',
    alignItems: 'start',
    justifyContent: 'center',
    p: 12,
    pt: 8,
    w: '50%',
    h: '100%',
  },
  '@bpMin1025': {
    p: 16,
    pt: 12,
  },
});

const TextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  g: 8,
});

const TextContainer = styled('div', {
  py: '$s300',
  maxW: '640px',
  '@bpMin769': {
    py: '$s400',
  },
});

const LogoImage = styled('img', {
  maxWidth: '60%',
  maxH: '30%',
  height: 'auto',
});

export default HeroModule;
